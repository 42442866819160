import React from 'react'
import Layout from '../components/layout'
import Main from '../components/home/main'

const IndexPage = () => {
  return (
    <Layout>
      <Main />
    </Layout>
  )
}

export default IndexPage
